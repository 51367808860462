<template>
  <FadeIn v-if="!quotePage" class="fixed bottom-4 right-4 z-[9] block shadow-lg md:bottom-5 md:right-5">
    <ElementButton
      class="block rounded-md bg-[#4d5ceb] px-5 py-2.5 text-white shadow-lg cursor-pointer transition-all duration-100 ease-in hover:bg-[#3645d0] z-[999]"
      url="/pricing" 
      type="primary"
      :arrow="true"
      :index="0"
    >
      Get a quote
    </ElementButton>
  </FadeIn>
</template>

<script setup>
const router = useRouter()
const quotePage = ref(false)

if (router.currentRoute.value.name === 'pricing' || router.currentRoute.value.name === 'pricing-new') {
  quotePage.value = true
}

watch(router.currentRoute, (newValue, oldValue) => {
  if (newValue.name === 'pricing' || newValue.name === 'pricing-new') {
    quotePage.value = true
  } else {
    quotePage.value = false
  }
})
</script>